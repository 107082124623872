.articles{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 2%;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 2%;
}

.article-info{
  height: 500px;
  width: 27%;
  margin-right: 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px lightgrey;
  box-shadow: 0 10px 15px 0 rgba(211,211,211, 0.5);
}

.article-info:nth-of-type(2n + 1) {
  background-color: #f5f1db;
}
span {
  padding: 5%;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
}

.read-more-button{
  width: 75%;
  height: 37px;
  margin-top: 2%  ;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-bottom: 2%;
}
.article-title{
  margin-top: 3%;
  margin-bottom: 3%;
}

@media screen and (max-width: 850px) {
  .article-info { width: 100%}
}