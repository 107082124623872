.booksContainer {
  width: 100%;
  background-color: #f5f1db;
  //margin-top: 100px;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  letter-spacing: 3px;
}

.books {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  font-family: Lato;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 2%;
  margin-bottom: 2%;
}

.book {
  width: 50%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}

.book .book-title p  {
  font-size: 20px;
}

.bookElement{
  margin-top: 2%;
}
.book .book-info{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.book-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.book-image {
  width: 200px;
  margin-right: 5%;
}

.read-buy-button{
  width: 263px;
  height: 37px;
  margin-top: 2%  ;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  margin-right: 3%;
  margin-bottom: 5%;
  color: white;
  border: none;
}

.books-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 2%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

h2 {
  font-family: 'Lato';
  text-align: center;
  letter-spacing: 3px;
    margin-top: 2%;
}

@media screen and (max-width: 1370px) {
  .books { width: 100%; flex-direction: column; justify-content: center; align-items: center}
  .book { width: 100%; margin-bottom: 10%  }
  .book-info {flex-direction: column}
  .books-buttons {flex-direction: column}
}