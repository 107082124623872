.article-content-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.article-back-button{
  width: 263px;
  height: 37px;
  margin-top: 2%  ;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
}

.article-content-info {
  text-align: center;
}

.article-content {
  padding: 2%;
  background-color: #f5f1db;
  width: 80%;
  overflow: hidden;
}

.article-text {
  width: 100%;
  height: 500px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.page-class {
  user-select: none;
}

.article-text * {
  user-select: none;
}

iframe {
  border: none;
}

.article-pdf {
  width: 100%;
  height: 700px;

}

embed {
  background: antiquewhite;
}