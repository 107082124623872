.footer-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f1db;
}

.footer-emails {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: normal;
}

.footer-mail{
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact {
  font-size: 22px;
}

h2 {
  margin-bottom: 2%;
  margin-top: 2%;
}
.publisher {
  margin-bottom: 25px;
  font-size: 25px;
}

.icons{
  width: 30px;
}

.copyright {
  font-size: 15px;
}

@media screen and (max-width: 850px) {
  .footer-emails { flex-direction: column}
  .footer-mail { width: 100%; margin-bottom: 2%; word-break: keep-all}
  h2 {margin-bottom: 5%; margin-top: 5%;}


}
