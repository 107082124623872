@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://fonts.googleapis.com/css?family=Lato);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.menuContainer {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #f5f1db;
  letter-spacing: 3px;
  border: solid 1px lightgrey;
  box-shadow: 0 10px 15px 0 rgba(211, 211, 211, 0.5);
  color: #76232f; }

p {
  font-family: 'Lato';
  font-size: 24px; }

.menuElement {
  width: 20%;
  text-align: center;
  vertical-align: top;
  cursor: pointer; }

.menuElement p {
  margin-top: 2.4rem; }

.menuLogo {
  width: 20%; }

.menuIcon {
  display: none; }

img {
  max-width: 100%;
  max-height: 100%; }

@media screen and (max-width: 1200px) {
  p {
    font-size: 20px; } }

@media screen and (max-width: 850px) {
  .menuElement {
    display: none; }
  .menuLogo {
    width: 50%;
    display: inline-block; }
  .menuContainer {
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .menuIcon {
    position: fixed;
    width: 10%;
    right: 5px;
    top: 5px;
    display: inline-block; }
  .menuContainer.responsive .menuElement {
    display: block;
    width: 50%;
    font-size: 14px;
    margin: auto;
    background-color: #f5f1db; }
  .menuContainer.responsive .menuLogo {
    display: none; } }

.booksContainer {
  width: 100%;
  background-color: #f5f1db;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 2%;
  letter-spacing: 3px; }

.books {
  width: 100%;
  margin: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  font-family: Lato;
  letter-spacing: 2px;
  font-size: 15px;
  margin-top: 2%;
  margin-bottom: 2%; }

.book {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.book .book-title p {
  font-size: 20px; }

.bookElement {
  margin-top: 2%; }

.book .book-info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }

.book-details {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }

.book-image {
  width: 200px;
  margin-right: 5%; }

.read-buy-button {
  width: 263px;
  height: 37px;
  margin-top: 2%;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  margin-right: 3%;
  margin-bottom: 5%;
  color: white;
  border: none; }

.books-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 2%; }

img {
  max-width: 100%;
  max-height: 100%; }

h2 {
  font-family: 'Lato';
  text-align: center;
  letter-spacing: 3px;
  margin-top: 2%; }

@media screen and (max-width: 1370px) {
  .books {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
  .book {
    width: 100%;
    margin-bottom: 10%; }
  .book-info {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .books-buttons {
    -webkit-flex-direction: column;
            flex-direction: column; } }

.bookInfo {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.bookElement {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 50%;
  margin-right: 5%;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: Lato; }

.bookElementImg {
  width: 354px;
  height: 517px; }

.buy-back-button {
  width: 263px;
  height: 37px;
  margin-top: 2%;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-right: 5%;
  margin-bottom: 5%; }

.book-info-content {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }

.book-info-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  margin-top: 5%; }

@media screen and (max-width: 850px) {
  .book-info-content {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center; }
  .book-info-buttons {
    -webkit-flex-direction: column;
            flex-direction: column; } }

#about {
  z-index: 0; }

.container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 2%; }

h2 {
  letter-spacing: 3px;
  text-align: center; }

article {
  margin-top: 2%;
  font-family: Lato;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px; }

.div-about {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #e7e3cc; }

.left-top {
  padding: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #f5f1db;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px; }

.right-top {
  padding: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: white; }

.left-bottom {
  padding: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: white; }

.right-bottom {
  padding: 1rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  background: #f5f1db;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px; }

.articles {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 90%;
  margin-top: 2%;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 2%; }

.article-info {
  height: 500px;
  width: 27%;
  margin-right: 3%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: solid 1px lightgrey;
  box-shadow: 0 10px 15px 0 rgba(211, 211, 211, 0.5); }

.article-info:nth-of-type(2n + 1) {
  background-color: #f5f1db; }

span {
  padding: 5%;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px; }

.read-more-button {
  width: 75%;
  height: 37px;
  margin-top: 2%;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-bottom: 2%; }

.article-title {
  margin-top: 3%;
  margin-bottom: 3%; }

@media screen and (max-width: 850px) {
  .article-info {
    width: 100%; } }

.slider {
  z-index: 1; }

.carousel-indicators {
  z-index: 1; }

.footer-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #f5f1db; }

.footer-emails {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: normal;
          align-items: normal; }

.footer-mail {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center; }

.contact {
  font-size: 22px; }

h2 {
  margin-bottom: 2%;
  margin-top: 2%; }

.publisher {
  margin-bottom: 25px;
  font-size: 25px; }

.icons {
  width: 30px; }

.copyright {
  font-size: 15px; }

@media screen and (max-width: 850px) {
  .footer-emails {
    -webkit-flex-direction: column;
            flex-direction: column; }
  .footer-mail {
    width: 100%;
    margin-bottom: 2%;
    word-break: keep-all; }
  h2 {
    margin-bottom: 5%;
    margin-top: 5%; } }

.map-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5); }

.map-iframe {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 100%; }

.map-popup-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  border-radius: 20px;
  background: white; }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0; }


.article-content-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top: 2%; }

.article-back-button {
  width: 263px;
  height: 37px;
  margin-top: 2%;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none; }

.article-content-info {
  text-align: center; }

.article-content {
  padding: 2%;
  background-color: #f5f1db;
  width: 80%;
  overflow: hidden; }

.article-text {
  width: 100%;
  height: 500px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

.page-class {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.article-text * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

iframe {
  border: none; }

.article-pdf {
  width: 100%;
  height: 700px; }

embed {
  background: antiquewhite; }

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.error-message {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }

.back-button {
  width: 263px;
  height: 37px;
  margin-top: 2%;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-bottom: 2%; }

