.menuContainer {
  width: 100%;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: #f5f1db;
  letter-spacing: 3px;
  border: solid 1px lightgrey;
  box-shadow: 0 10px 15px 0 rgba(211,211,211, 0.5);
  color: #76232f;
  //height: 100px; need in page jumps but to be talked
}

p {
  font-family: 'Lato';
  font-size: 24px;
}

.menuElement {
  width: 20%;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
}

.menuElement p {
  margin-top: 2.4rem;
}
.menuLogo {
  width: 20%;
}

.menuIcon {
  display: none
}

img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (max-width: 1200px) {
  p { font-size: 20px}
}

@media screen and (max-width: 850px) {
  .menuElement { display: none}
  .menuLogo { width: 50%; display: inline-block;}
  .menuContainer { align-items: flex-start; justify-content: flex-start; flex-direction: column;}
  .menuIcon { position: fixed;  width: 10%; right: 5px; top: 5px; display:inline-block}
  .menuContainer.responsive .menuElement { display: block; width: 50%; font-size: 14px; margin: auto; background-color: #f5f1db}
  .menuContainer.responsive .menuLogo { display: none}

}

//.menuContainer.responsive{height: 360px;}