.error-message{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.back-button{
  width: 263px;
  height: 37px;
  margin-top: 2%  ;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-bottom: 2%;
}