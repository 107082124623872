.bookInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bookElement {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 50%;
  margin-right: 5%;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: Lato;

}

.bookElementImg{
  width: 354px;
  height: 517px;
}

.buy-back-button{
  width: 263px;
  height: 37px;
  margin-top: 2%  ;
  background-color: #d0c899;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 1px;
  color: white;
  border: none;
  margin-right: 5%;
    margin-bottom: 5%;
}
.book-info-content {
  display: flex;
  flex-direction: row;
}

.book-info-buttons {
  display: flex;
  flex-direction: row;
  margin-top: 5%;

}

@media screen and (max-width: 850px) {
  .book-info-content { flex-direction: column; align-items: center}
  .book-info-buttons { flex-direction: column}
}