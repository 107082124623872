#about {
  z-index: 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

h2{
  letter-spacing: 3px;
  text-align: center;
}

article {
  margin-top: 2%;
  font-family: Lato;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
}

.div-about {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #e7e3cc;
}

.left-top {
  padding: 1rem;
  flex: 1;
  background: #f5f1db;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
}

.right-top {
  padding: 1rem;
  flex: 1;
  background: white;
}

.left-bottom {
  padding: 1rem;
  flex: 1;
  background: white;
}

.right-bottom {
  padding: 1rem;
  flex: 1;
  background: #f5f1db;
  font-family: Lato;
  text-align: justify;
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 1px;
}